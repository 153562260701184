import React from 'react';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Tooltip } from '@material-ui/core';
import { InsertPhoto } from 'utilsModule/material-ui-icons';
import { omit } from 'ramda';
import { validators } from 'utilsModule/form';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';

export default class ImageAvatar extends PureComponent {
  static propTypes = {
    gothumb: PropTypes.bool,
    src: PropTypes.string,
    defaultUrl: PropTypes.string,
    alt: PropTypes.node,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    gothumb: true,
    alt: <InsertPhoto />,
    tooltip: '',
  };

  state = {
    url: !this.props.gothumb || validators.dataUrl(this.props.src) ? this.props.src : null,
  };

  componentDidMount() {
    const { gothumb, src } = this.props;
    src &&
      gothumb &&
      !validators.dataUrl(src) &&
      fetchGoThumbSignedUrl(src)
        .then((url) => this.setState({ url }))
        .catch((error) => {
          // MILO-1581
          this.setState({ url: null });
          console.warn(`%cUnable to fetch image from gothumb for ${src}`, 'font-size: 12px; color: lightcoral', error);
        });
  }

  componentDidUpdate({ src: prevSrc }) {
    const { gothumb, src } = this.props;
    if (src !== prevSrc) {
      src && gothumb && !validators.dataUrl(src)
        ? fetchGoThumbSignedUrl(this.props.src)
            .then((url) => this.setState({ url }))
            .catch((error) => {
              // MILO-1581
              this.setState({ url: null });
              console.warn(
                `%cUnable to fetch image from gothumb for ${this.props.src}`,
                'font-size: 12px; color: lightcoral',
                error,
              );
            })
        : this.setState({ url: src });
    }
  }

  render() {
    const { url } = this.state;
    const { defaultUrl, alt, tooltip, altStyle = {} } = this.props;
    const passedThroughProps = omit(['gothumb', 'src', 'defaultUrl', 'alt'], this.props);

    return (
      <Tooltip title={tooltip}>
        {url || defaultUrl ? (
          <Avatar src={url || defaultUrl} {...passedThroughProps} />
        ) : (
          <Avatar {...passedThroughProps}>
            <span style={altStyle}>{alt}</span>
          </Avatar>
        )}
      </Tooltip>
    );
  }
}
