/* eslint-disable */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { ModeEdit, CheckCircle, Error } from 'utilsModule/material-ui-icons';
import * as R from 'ramda';

/* NOTE: All these converter returns '' on '' & 0 for '0' */
const toNumber = (value, previousValue) => {
  if (value === '') return '';
  if (value === '0') return 0;
  return parseFloat(value) || previousValue;
}
const toInteger = (value, previousValue) => {
  if (value === '') return '';
  if (value === '0') return 0;
  return parseInt(value) || previousValue;
}
const toFloat = noFloatingPoints => (value, previousValue) => {
  if (value === '') return null;
  if (value === '0') return 0;
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) return previousValue;
  const finalValue = Math.round(floatValue * Math.pow(10, noFloatingPoints)) / Math.pow(10, noFloatingPoints);
  return finalValue;
}
const toDigits = R.replace(/[^\d]/g, '');
const within = (min = -Infinity, max = Infinity) => (value, previousValue) =>
  isNaN(value) || min > value || max < value ? previousValue : value;

export const isNumeric = R.pipe(parseFloat, isNaN, R.not);

export const formatPhone = (delimiter = '-') => ({ countryCode, areaCode = '', value }) => {
  const localFormat = (() => {
    switch (countryCode) {
      // Singapore
      case '65': return value => `${R.take(4)(value)}${delimiter}${R.takeLast(4)(value)}`;
      default: return R.identity;
    };
  })();
  return `+${countryCode} ${areaCode}${areaCode ? delimiter : ''}${localFormat(value)}`;
}

// TODO: Move this out of normalize
export const normalize = {
  toNumber,
  toInteger,
  toFloat,
  toDigits,
  within,
};

export const validators = {
  dataUrl: R.test(/^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*)\s*$/i),
  email: R.anyPass([R.isNil, R.isEmpty, R.test(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
};

export const defaultMeta = { pristine: true, dirty: false, touchedValid: false, visiblyInvalid: false };

export const reduceMetas = metas => ({
  pristine: R.all(R.prop('pristine'), R.values(metas)),
  dirty: R.any(R.prop('dirty'), R.values(metas)),
  touchedValid: R.all(R.prop('touchedValid'), R.values(metas)),
  visiblyInvalid: R.any(R.prop('visiblyInvalid'), R.values(metas)),
});

export const decideStatusFromMeta = meta => {
  let status;
  meta.dirty && (status = 'dirty');
  // NOTE: if both pristine & touchedValid, show as pristine
  meta.touchedValid && (status = 'touchedValid');
  meta.pristine && (status = 'pristine');
  meta.visiblyInvalid && (status = 'visiblyInvalid');
  return status;
}

@withStyles({
  pristine: {
    color: '#00b3b3',
  },
  dirty: {
    color: '#FFC107',
  },
  touchedValid: {
    color: '#81C784',
  },
  visiblyInvalid: {
    color: 'lightcoral',
  },
})
export class StatusIndicator extends PureComponent {
  static propTypes = {
    status: PropTypes.string.isRequired,
    pristineIcon: PropTypes.func,
    visible: PropTypes.bool,
    active: PropTypes.bool,
  };

  render() {
    const { status, pristineIcon: PristineIcon = '', visible = true, active = true, classes } = this.props;

    if (R.complement(R.contains)(status, ['pristine', 'dirty', 'touchedValid', 'visiblyInvalid']) && process.env.DEBUG)
      throw new TypeError(`Unexpected status ${status}`);

    return ({
      pristine: visible ? PristineIcon && <PristineIcon color={active ? 'primary' : 'disabled'} classes={{ colorPrimary: classes.pristine }} /> : '',
      dirty: visible ? <ModeEdit color={active ? 'primary' : 'disabled'} classes={{ colorPrimary: classes.dirty }} /> : '',
      touchedValid: visible ? <CheckCircle color={active ? 'primary' : 'disabled'} classes={{ colorPrimary: classes.touchedValid }} /> : '',
      visiblyInvalid: visible ? <Error color={active ? 'primary' : 'disabled'} classes={{ colorPrimary: classes.visiblyInvalid }} /> : '',
    })[status];
  }
}
