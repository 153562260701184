/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Divider, TableCell, Checkbox, Radio, Typography, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  PagingState,
  SelectionState,
  IntegratedSelection,
  CustomPaging,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid as TableGrid,
  Table,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  TableSelection,
  TableRowDetail,
  TableColumnResizing,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import ExpandMore from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import * as R from 'ramda';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

@withStyles(theme => ({
  cell: {
    overflow: 'visible',
    paddingRight: 0,
    paddingLeft: theme.spacing(),
    textAlign: 'center',
  },
  control: {
    marginTop: '-1px',
    marginBottom: '-1px',
    width: theme.spacing(1),
    height: theme.spacing(2),
  },
  radio: {
    '&$checked': {
      color: '#FF5722'
    }
  },
  checked: {},
}), { name: 'MiloTableSelectCell' })
class MiloTableSelectCell extends React.PureComponent {
  static propTypes = {
    control: PropTypes.object.isRequired,
    style: PropTypes.object,
    selected: PropTypes.bool,
    onToggle: PropTypes.func,
    classes: PropTypes.object.isRequired,
    row: PropTypes.any,
    tableRow: PropTypes.object,
    tableColumn: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      control: Control, style, selected, onToggle, classes, disabled,
      className, row, tableRow, tableColumn,
      ...rest
    } = this.props;

    return (
      <TableCell
        padding='checkbox'
        style={style}
        className={classNames(classes.cell, className)}
        {...rest}>
        <Control
          className={classes.control}
          classes={{root: classes.radio, checked: classes.checked}}
          checked={!!selected}
          disabled={row.disabled ? row.disabled : !!disabled}
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
        />
      </TableCell>
    );
  }
}
class CustomToggle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
    };
  }

  handleChange = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
    this.props.onToggle();
  }

  render() {
    const { expanded } = this.state;
    let padding = '16px';
    if (this.props.row && this.props.row.padding) padding = this.props.row.padding;
    return (
      <td style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: '8px',
        paddingRight: '8px',
        textOverflow: 'ellipsis',
        color: '#212121',
        display: 'table-cell',
        padding: padding,
        fontSize: '14px',
        fontStyle: 'normal',
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        lineHeight: 1.14,
        fontStretch: 'normal',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        letterSpacing: '0.5px',
        verticalAlign: 'inherit',
      }}>
        <Grid>
          <span
            onClick={this.handleChange}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </span>
        </Grid>
      </td>
    );
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        borderBottom: 'transparent',
        fontSize: '12px',
        fontWeight: 500,
        color: '#9e9e9e',
        paddingLeft: ' 24px'
      },
      body: {
        fontWeight: 'normal',
      },
      root: {
        display: 'table-cell',
        height: '39px',
        padding: '0px 24px',
        fontSize: '13px',
        fontStyle: 'normal',
        textAlign: 'left',
        lineHeight: 1.14,
        fontStretch: 'normal',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        letterSpacing: '0.5px',
        verticalSlign: 'inherit',
        color: '#9e9e9e',
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#212121',
      }
    },
    MuiTypography: {
      caption: {
        color: '#9E9E9E',
        fontsize: '12px',
        fontstyle: 'normal',
        fontWeight: 'normal',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineheight: 1.17,
        fontStretch: 'normal',
        letterSpacing: '0.06px',
        opacity: 1,
      }
    },
    MuiGrid: {
      root: {
        paddingTop: '8px'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          height: '1px',
          backgroundColor: 'transparent',
          borderBottom: 'transparent'
        },
        '&:after': {
          height: '1px',
          background: 'transparent',
          borderBottom: 'transparent',
        },
      }
    },
    MuiSelect: {
      root: {
        paddingTop: '0px',
        paddingRight: '0px',
        paddingBottom: '0px',
      },
      icon: {
        paddingTop: '0px',
      },
    },
    TableFixedCell: {
      fixedCell: {
        zIndex: 9,
        position: 'sticky',
        backgroundClip: 'padding-box',
        backgroundColor: '#fff',
        width: '50px',
      },
      dividerLeft: {
        borderLeft: '1px solid rgba(0, 0, 0, -0.88)',
      },
    },
    TableInvisibleRow: {
      row: {
        display: 'none'
      }
    },
  }
});

@withStyles(theme => ({
  rootContainer: {
    padding: `${theme.spacing(3)}px`,
    borderRadius: 10,
    position: 'relative',
    boxShadow: '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  loaderWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: { color: '#ff5100' },
}), { name: 'MiloTable' })
class MiloTable extends React.PureComponent {
  static propTypes = {
    title: PropTypes.shape({
      components: PropTypes.array,
      itemProps: PropTypes.object,
      containerProps: PropTypes.object,
      divider: PropTypes.bool,
    }),
    detail: PropTypes.shape({
      components: PropTypes.array,
      itemProps: PropTypes.object,
      containerProps: PropTypes.object,
      divider: PropTypes.bool,
    }),
    header: PropTypes.shape({
      components: PropTypes.array,
      itemProps: PropTypes.object,
      containerProps: PropTypes.object,
      divider: PropTypes.bool,
    }),
    selectionAll: PropTypes.shape({
      onSelectionAll: PropTypes.func.isRequired,
      selectAll: PropTypes.bool,
    }),
    virtualized: PropTypes.bool,
    content: PropTypes.shape({
      rows: PropTypes.array.isRequired,
      columns: PropTypes.array.isRequired,
      columnExtensions: PropTypes.array,
    }).isRequired,
    options: PropTypes.shape({
      pagination: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalCount: PropTypes.number.isRequired,
        handleCurrentPageChange: PropTypes.func.isRequired,
      }),
      // Filtering
      // Sorting
    }),
    selection: PropTypes.shape({
      singleSelect: PropTypes.bool,
      ids: PropTypes.array,
      indexes: PropTypes.array,
      onSelectionChange: PropTypes.func.isRequired,
    }),
    footer: PropTypes.shape({
      components: PropTypes.array,
      itemProps: PropTypes.object,
      containerProps: PropTypes.object,
      divider: PropTypes.bool,
    }),
    loading: PropTypes.bool,
    rowDetail: PropTypes.func,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  getSelectedRows = selection => R.pipe(
    R.addIndex(R.map)((row, index) => ({ ...row, index })),
    R.filter(({ id, index }) =>
      R.propOr([], 'ids', selection).includes(id) ||
      R.propOr([], 'indexes', selection).includes(index)),
    R.when(R.always(R.prop('singleSelect', selection)), R.take(1)),
  );

  render() {
    const {
      title: {
        components: titleComponents,
        itemProps: titleItemProps,
        containerProps: titleContainerProps,
        divider: titleDivider,
      } = {},
      detail: {
        components: detailComponents,
        itemProps: detailItemProps,
        containerProps: detailContainerProps,
        divider: detailDivider,
      } = {},
      header: {
        components: headerComponents,
        itemProps: headerItemProps,
        containerProps: headerContainerProps,
        divider: headerDivider,
      } = {},
      selectionAll,
      virtualized = false,
      content: { rows, columns, columnExtensions = [] },
      options,
      advanced: {
        tableGridProps,
        tableProps,
        virtualTableProps,
        tableColumnResizingProps,
        tableHeaderRowProps,
        tableSelectionProps } = {},
      selection,
      footer: {
        components: footerComponents,
        itemProps: footerItemProps,
        containerProps: footerContainerProps,
        divider: footerDivider,
      } = {},
      loading,
      rowDetail,
      classes,
      disabled: tableDisabled,
      readonly: tableReadonly,
      containerProps,
      paperStyle = null,
      showHeaderUnderline = true,
      showCustomNumber = false,
      handleRowPerPage,
      rowPerPageValue = 5,
      rowPerPageOptions = [5, 10, 15],
      leftColumns = [],
      rightColumns = [],
      useTableFixedColumns = false,
      selectionStyle = null,
      showHeaderDivider = false,
      headerDividerStyle = null,
      ...rest
    } = this.props;

    const pagination = options && options.pagination;
    const resize = tableColumnResizingProps;

    return (
      <Paper className={classes.rootContainer} style={paperStyle}>
        <Grid container spacing={0} {...containerProps}>
          {titleComponents && (
            <Grid item xs={12} {...titleItemProps}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                {...titleContainerProps}>
                {titleComponents.map(({
                  id: titleKey,
                  component: Component,
                  inject,
                  colSpans: titleColSpans,
                  ...props
                }) => (
                  <Grid key={titleKey} item {...titleColSpans}>
                    <Component
                      disabled={!!tableDisabled}
                      readonly={!!tableReadonly}
                      {...props}
                      {...(inject ? inject(rest) : {})} />
                  </Grid>
                ))}
                {titleDivider && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {detailComponents && (
            <Grid item xs={12} {...detailItemProps}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                {...detailContainerProps}>
                {detailComponents.map(({
                  id: detailKey,
                  component: Component,
                  inject,
                  colSpans: detailColSpans,
                  ...props
                }) => (
                  <Grid key={detailKey} item {...detailColSpans}>
                    <Component
                      disabled={!!tableDisabled}
                      readonly={!!tableReadonly}
                      {...props}
                      {...(inject ? inject(rest) : {})} />
                  </Grid>
                ))}
                {detailDivider && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {headerComponents && (
            <Grid item xs={12} {...headerItemProps}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                {...headerContainerProps}>
                {headerComponents.map(({
                  id: headerKey,
                  component: Component,
                  inject,
                  colSpans: headerColSpans,
                  fullWidth,
                  ...props
                }) => (
                  <Grid style={{ width: fullWidth ? '100%' : '' }} key={headerKey} item {...headerColSpans}>
                    <Component
                      disabled={!!tableDisabled}
                      readonly={!!tableReadonly}
                      {...props}
                      {...(inject ? inject(rest) : {})} />
                  </Grid>
                ))}
                {headerDivider && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {showHeaderDivider && <Grid item xs={12}>
            <Divider style={headerDividerStyle} />
          </Grid>}
          {selectionAll && <Grid style={selectionStyle}><Checkbox onChange={selectionAll.onSelectionAll} checked={selectionAll.selectAll} /></Grid>}
          {showHeaderUnderline ?
            <Grid item xs={12}>
              <TableGrid
                rows={R.when(
                  R.always(tableReadonly && selection),
                  this.getSelectedRows(selection),
                )(rows)}
                columns={columns} {...tableGridProps}>
                {/* State Management Plugins */}
                {!virtualized && pagination && (
                  <PagingState
                    currentPage={pagination.currentPage}
                    onCurrentPageChange={pagination.handleCurrentPageChange}
                    pageSize={pagination.pageSize}
                    onPageSizeChange={pagination.handlePageSizeChange}
                  />
                )}
                {selection && (
                  <SelectionState
                    selection={R.when(
                      R.always(selection.singleSelect),
                      R.take(1),
                    )(selection.ids || selection.indexes)}
                    onSelectionChange={R.pipe(
                      // ASSUME: selected is always appended to the end of the array selection
                      R.when(
                        R.always(selection.singleSelect),
                        R.pipe(
                          R.takeLast(1),
                          R.when(
                            R.isEmpty,
                            R.always(selection.ids || selection.indexes),
                          ),
                        ),
                      ),
                      R.unless(R.identity, R.identity)(selection.onSelectionChange),
                    )}
                  />
                )}
                {/* Data Processing Plugins (Order is important) */}
                {!virtualized && pagination && <CustomPaging totalCount={pagination.totalCount} />}
                {selection && <IntegratedSelection />}
                <RowDetailState />
                {virtualized
                  ? <VirtualTable {...virtualTableProps} columnExtensions={columnExtensions} />
                  : <Table {...tableProps} columnExtensions={columnExtensions} />}
                {resize && <TableColumnResizing {...tableColumnResizingProps} />}
                {/* UI Plugins */}
                <TableHeaderRow {...tableHeaderRowProps} />
                {/* TODO: Pass disabled & readonly to rowDetail */}
                {rowDetail && <TableRowDetail contentComponent={rowDetail} toggleCellComponent={CustomToggle} />}
                {!virtualized && pagination && <PagingPanel pageSizes={pagination.pageSizes} containerComponent={tableReadonly ? R.always('') : PagingPanel.Container} />}
                {!tableReadonly && selection && <TableSelection
                  cellComponent={props =>
                    <MiloTableSelectCell
                      {...props}
                      control={selection.singleSelect ? Radio : Checkbox}
                      disabled={!!tableDisabled} />}
                  {...tableSelectionProps} />}
              </TableGrid>
            </Grid>
            :
            <ThemeProvider theme={theme}>
              <Grid item xs={12}>
                <TableGrid
                  rows={R.when(
                    R.always(tableReadonly && selection),
                    this.getSelectedRows(selection),
                  )(rows)}
                  columns={columns}
                  {...tableGridProps}>
                  {/* State Management Plugins */}
                  {!virtualized && pagination && (
                    <PagingState
                      currentPage={pagination.currentPage}
                      onCurrentPageChange={pagination.handleCurrentPageChange}
                      pageSize={pagination.pageSize}
                      onPageSizeChange={pagination.handlePageSizeChange}
                    />
                  )}
                  {selection && (
                    <SelectionState
                      selection={R.when(
                        R.always(selection.singleSelect),
                        R.take(1),
                      )(selection.ids || selection.indexes)}
                      onSelectionChange={R.pipe(
                        // ASSUME: selected is always appended to the end of the array selection
                        R.when(
                          R.always(selection.singleSelect),
                          R.pipe(
                            R.takeLast(1),
                            R.when(
                              R.isEmpty,
                              R.always(selection.ids || selection.indexes),
                            ),
                          ),
                        ),
                        R.unless(R.identity, R.identity)(selection.onSelectionChange),
                      )}
                    />
                  )}
                  {/* Data Processing Plugins (Order is important) */}
                  {!virtualized && pagination && <CustomPaging totalCount={pagination.totalCount} />}
                  {selection && <IntegratedSelection />}
                  <RowDetailState />
                  {virtualized
                    ? <VirtualTable {...virtualTableProps} columnExtensions={columnExtensions} />
                    : <Table {...tableProps} columnExtensions={columnExtensions} />}
                  {resize && <TableColumnResizing {...tableColumnResizingProps} />}
                  {/* UI Plugins */}
                  <TableHeaderRow {...tableHeaderRowProps} />
                  {/* TODO: Pass disabled & readonly to rowDetail */}
                  {rowDetail && <TableRowDetail contentComponent={rowDetail} toggleCellComponent={CustomToggle} />}
                  {useTableFixedColumns && <TableFixedColumns leftColumns={leftColumns} rightColumns={rightColumns} />}
                  {!virtualized && pagination &&
                    <PagingPanel
                      pageSizes={pagination.pageSizes}
                      containerComponent={tableReadonly ? R.always('') : PagingPanel.Container}
                    />}
                  {!tableReadonly && selection && <TableSelection
                    cellComponent={props =>
                      <MiloTableSelectCell
                        {...props}
                        control={selection.singleSelect ? Radio : Checkbox}
                        disabled={!!tableDisabled} />}
                    {...tableSelectionProps} />}
                </TableGrid>
                {showCustomNumber &&
                  <Grid container direction='row' style={{ paddingLeft: '30px', marginTop: '-55px' }}>
                    <Typography style={{
                      textAlign: 'left',
                      fontSize: '14px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 'normal',
                      letterSpacing: '0.07px',
                      color: '#212121',
                      opacity: 1,
                      paddingRight: '5px'
                    }}>Rows Per Page: </Typography>
                    <Select
                      value={rowPerPageValue}
                      onChange={handleRowPerPage}
                    >
                      {rowPerPageOptions.map((m, i) => (
                        <MenuItem key={i} value={m}>
                          <Typography style={{
                            textAlign: 'left',
                            fontWeight: 'normal',
                            fontSize: '14px',
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            letterSpacing: '0.07px',
                            color: '#212121',
                            opacity: 1,
                          }}>{m}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>}
              </Grid>
            </ThemeProvider>
          }
          {footerComponents && (
            <Grid item xs={12} {...footerItemProps}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                {...footerContainerProps}>
                {footerDivider && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
                {footerComponents.map(({
                  id: footerKey,
                  component: Component,
                  inject,
                  colSpans: footerColSpans,
                  ...props
                }) => (
                  <Grid key={footerKey} item style={{ maxWidth: '100%', overFlowWrap: 'break-word' }} {...footerColSpans}>
                    <Component
                      disabled={!!tableDisabled}
                      readonly={!!tableReadonly}
                      {...props}
                      {...(inject ? inject(rest) : {})} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        {
          loading && (
            <div className={classes.loaderWrapper}>
              <CircularProgress className={classes.loader} />
            </div>
          )
        }
      </Paper>
    );
  }
}

export default MiloTable;
