/* eslint-disable */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HeaderComponent from '../../app/main/layouts/Header';
import { withStyles } from '@material-ui/styles';
import IconAccessDenied from '../assets/IconAccessDenied.png';

const styles = theme => ({
  card: {
    maxWidth: 345,
    textAlign: 'center',
    margin: `${theme.spacing(5)}px auto`,
  },
  media: {
    padding: `${theme.spacing(3)}px`,
    height: 200,
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  icon: {
    textAlign: 'center',
    paddingTop: '150px',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
  },
  desc: {
    textAlign: 'center',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    paddingTop: '5px',
    font: 'normal normal normal 16px/20px Roboto',
    letterSpacing: '0px'
  },
  content: {
    textAlign: 'center',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    paddingTop: '5px',
    font: 'normal normal bold 34px/40px Roboto',
    letterSpacing: '0px',
  },
  button: {
    width: '180px',
    color: 'white',
    backgroundColor: '#ff5100',
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
});

const Unauthorized = ({ classes, authorizations, errLabel, includeWsb, history, path, props }) => (
  <Grid>
    {!includeWsb && <HeaderComponent {...props} />}
    <Grid style={{ width: '100%' }}>
      <Typography
        className={classes.icon}
        style={{ width: includeWsb ? '87vw' : '100%' }}
      >
        <img
          src={IconAccessDenied}
          style={{ width: '250px' }}
        />
      </Typography>
      <Typography variant="h5" component="h2" className={classes.content} style={{ width: includeWsb ? '87vw' : '100%' }}>Access Denied {errLabel}</Typography>
      <Typography variant='caption' className={classes.desc} style={{ width: includeWsb ? '87vw' : '100%', display: 'block' }}>
        This page you are trying to access has restricted access. <br /> Please reach out to your organisation system administrator.
      </Typography>
      {!includeWsb &&
        <Grid>
          <Typography className={classes.desc} style={{ width: includeWsb ? '87vw' : '100%', paddingTop: '10px' }}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => history.push(path)}
            >
              Return
            </Button>
          </Typography>
        </Grid>
      }
    </Grid>
  </Grid>
);

export default withStyles(styles)(Unauthorized);