/* eslint-disable no-inline-style */
import * as R from 'ramda';
import { roundFloatToFixedDecimal } from 'utilsModule/common';
import {
  VITAL_TYPE_MAPPING,
  VITAL_NAME_MAPPING,
  TASK_STATUS_MAPPING,
  VITAL_DISPLAY_MAPPING,
  MEALTIME_MAPPING,
  MEALTIME_NAME_MAPPING
} from 'dataModule/constants/vital';

const getVitalTaskStatus = status => TASK_STATUS_MAPPING[status];

// get vital type
const getVitalType = R.prop(R.__, VITAL_NAME_MAPPING);

// get vital display name
const getVitalName = R.prop(R.__, VITAL_TYPE_MAPPING);

// get Vital Type From Array such as ['BP', 'HR']
const getVitalTypeFromArray = R.compose(
  getVitalType,
  getVitalName,
  R.toUpper,
  R.join('_'),
);
const isMissedReading = R.compose(
  R.complement(R.isNil),
  R.prop('dataAbsentReason'),
);
// get reading from observation collection
const getReading = observation => {
  if (!observation) return null;
  if (isMissedReading(observation)) return 'Missed Reading';
  const { component, valueQuantity } = observation;
  const vitalType = R.pathOr('', ['vitals', 0], observation) || R.pathOr('', ['code', 'text'], observation);

  if (valueQuantity) {
    // For HS round to 2 decimal places
    if (vitalType === 'HS') {
      return `${roundFloatToFixedDecimal(Number(valueQuantity.value), 2).toFixed(2)} ${valueQuantity.unit}`;
    } else if (vitalType === 'HR') {
      return `${roundFloatToFixedDecimal(Number(valueQuantity.value), 0).toFixed()} ${valueQuantity.unit}`;
    }
    return `${roundFloatToFixedDecimal(Number(valueQuantity.value), 1).toFixed(1)} ${valueQuantity.unit}`;
  }
  if (!component) return null;
  const getUnit = R.path([0, 'valueQuantity', 'unit']);
  const getValue = R.path(['valueQuantity', 'value']);
  const unit = getUnit(component);
  let values = R.map(getValue)(component);
  if (vitalType === 'HS') {
    values = values.map(m => roundFloatToFixedDecimal(Number(m), 2).toFixed(2));
  } else if (vitalType === 'HR') {
    values = values.map(m => roundFloatToFixedDecimal(Number(m), 0).toFixed());
  } else {
    values = values.map(m => roundFloatToFixedDecimal(Number(m), 1).toFixed(1));
  }
  return `${values.join('/')} ${unit}`;
};

const getReadingForRender = (observation, vitalLabel = false) => {
  if (!observation) return [];
  if (isMissedReading(observation)) return [{ reading: 'Missed' }];
  const getUnit = R.path(['valueQuantity', 'unit']);
  const getInterpretation = R.path(['interpretation', 'coding', 0, 'code']);
  const getValue = R.path(['valueQuantity', 'value']);
  const { component, valueQuantity } = observation;
  const isNumber = val => /^\d+(\.\d+)*$/.test(val);
  if (valueQuantity) {
    if (vitalLabel) {
      return [
        {
          interpretation: getInterpretation(observation),
          reading: isNumber(getValue(observation)) ? parseFloat(getValue(observation)) : getValue(observation),
          vitalName: VITAL_DISPLAY_MAPPING[R.toLower(vitalLabel)],
          unit: getUnit(observation),
        },
      ];
    }
    return [
      {
        reading: isNumber(getValue(observation)) ? parseFloat(getValue(observation)) : getValue(observation),
      }
    ];
  }
  return R.map(
    c => ({
      interpretation: getInterpretation(c),
      reading: isNumber(getValue(c)) ? parseFloat(getValue(c)) : getValue(c),
      vitalName: VITAL_DISPLAY_MAPPING[R.toLower(R.path(['code', 'coding', 0, 'display'], c))],
      unit: getUnit(c),
    }),
    component,
  );
};

// observations: Array
const getReadingFromTask = R.compose(
  R.join(' '),
  R.reject(R.either(R.isNil, R.equals('Missed Reading'))),
  R.map(getReading),
);

/*
 * When traillingZeros is true, .0000 will be returned
 * When it is false, 54.000 will be returned as 54 even if it is fixed to a certain decimal place
 */
export const normalizeVitalsDecimals = (rawValue, label, traillingZeros = true) => {
  if (traillingZeros) {
    if (label === 'Body Weight') {
      return roundFloatToFixedDecimal(Number(rawValue), 2).toFixed(2);
    }
    if (label === 'Heart Rate') {
      return roundFloatToFixedDecimal(Number(rawValue), 0).toFixed();
    }
    return roundFloatToFixedDecimal(Number(rawValue), 1).toFixed(1);
  } else {
    if (label === 'Body Weight') {
      return roundFloatToFixedDecimal(Number(rawValue), 2);
    }
    if (label === 'Heart Rate') {
      return roundFloatToFixedDecimal(Number(rawValue), 0);
    }
    return roundFloatToFixedDecimal(Number(rawValue), 1);
  }
};

const getMealtime = when => MEALTIME_MAPPING[when];

const getMealtimeTaken = when => MEALTIME_NAME_MAPPING[when];

export default {
  getVitalTaskStatus,
  getVitalType,
  getVitalTypeFromArray,
  getVitalName,
  getReading,
  getReadingFromTask,
  getReadingForRender,
  normalizeVitalsDecimals,
  getMealtime,
  getMealtimeTaken
};
