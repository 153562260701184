/* eslint-disable */
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  card: {
    maxWidth: 345,
    textAlign: 'center',
    margin: `${theme.spacing(5)}px auto`,
  },
  media: {
    padding: `${theme.spacing(3)}px`,
    height: 200,
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  icon: {
    textAlign: 'center',
    paddingTop: '150px',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
  },
  desc: {
    textAlign: 'center',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    paddingTop: '5px',
    font: 'normal normal normal 16px/20px Roboto',
    letterSpacing: '0px'
  },
  content: {
    textAlign: 'center',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    paddingTop: '5px',
    font: 'normal normal bold 34px/40px Roboto',
    letterSpacing: '0px',
  },
  button: {
    width: '180px',
    color: 'white',
    backgroundColor: '#ff5100',
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
});

const SessionTimeout = ({ classes, errLabel }) => (
  <div style={{display:'none'}}/>
);

export default withStyles(styles)(SessionTimeout);