import { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as R from 'ramda';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { getters as usersGetters } from 'dataModule/store/resources/users';
import { PersonOutline } from 'utilsModule/material-ui-icons';
import uuidv4 from 'uuid/v4';
import globalSidebar from 'utilsModule/globalSidebar';
import { ROLES, ORG_TYPES } from 'dataModule/constants/profile';
import { connect } from 'react-redux';
import Unauthorized from './Unauthorized';
import unauthorized from 'appModule/unauthorized';

const authorize = ({ role: loggedInRole, orgType: loggedInOrgType }) =>
    R.anyPass([
        R.isNil,
        R.isEmpty,
        R.any(
            ({ role: requiredRole, orgType: requiredOrgType }) =>
            (!requiredRole || requiredRole === loggedInRole) && (!requiredOrgType || requiredOrgType === loggedInOrgType),
        ),
    ]);

const routes = [{
        path: 'orgs',
        resource: 'ORGANISATION',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'orgs',
        resource: 'ORGANISATION',
        operation: 'CREATE',
        sub: [],
    },
    {
        path: 'providers',
        resource: 'PROVIDER',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'providers',
        resource: 'PROVIDER',
        operation: 'CREATE',
        sub: [],
    },
    {
        path: 'careteams',
        resource: 'CARE_TEAM',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'careteams',
        resource: 'CARE_TEAM',
        operation: 'CREATE',
        sub: [],
    },
    {
        path: 'programs',
        resource: 'ADMIN_PROGRAMME',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'programs',
        resource: 'ADMIN_PROGRAMME',
        operation: 'CREATE',
        sub: [],
    },
    {
        path: 'chargecode',
        resource: 'CHARGE_CODE',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'chargecode',
        resource: 'CHARGE_CODE',
        operation: 'CREATE',
        sub: [],
    },
    {
        path: 'consumables',
        resource: 'CONSUMABLE',
        operation: 'ISSUE_CONSUMABLE',
        sub: [],
    },
    {
        path: 'inventory',
        resource: 'INVENTORY',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'invoices',
        resource: 'INVOICE',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
    {
        path: 'participants',
        resource: 'PARTICIPANT',
        operation: 'CREATE',
        sub: [],
    },
    {
        path: 'participants',
        resource: 'PARTICIPANT',
        operation: 'SUB',
        sub: [
            { path: 'program', resource: 'PARTICIPANT', operation: 'VIEW_PROGRAMME' },
            { path: 'vitals', resource: 'PARTICIPANT', operation: 'VIEW_VITAL' },
            { path: 'caretask', resource: 'PARTICIPANT', operation: 'VIEW_CARE_TASK' },
            { path: 'devices', resource: 'PARTICIPANT', operation: 'VIEW_DEVICE' },
            { path: 'alerts', resource: 'PARTICIPANT', operation: 'VIEW_ALERT' },
            { path: 'adherence', resource: 'PARTICIPANT', operation: 'VIEW_PROGRAMME' },
            { path: 'reporting', resource: 'PARTICIPANT', operation: 'VIEW_PROGRAMME' },
        ],
    },
    {
        path: 'programs',
        resource: 'ADMIN_PROGRAMME',
        operation: 'VIEW_INDIVIDUAL_PROGRAMME_PROVIDERS_ACL',
        sub: [],
    },
    {
        path: 'consumables',
        resource: 'CONSUMABLE',
        operation: 'ISSUE_CONSUMABLE_PROVIDERS_ACL',
        sub: [],
    },
    {
        path: 'inventory',
        resource: 'INVENTORY',
        operation: 'VIEW_INDIVIDUAL_INVENTORY_PROVIDERS_ACL',
        sub: [],
    },
    {
        path: 'reporting',
        resource: 'PARTICIPANT',
        operation: 'VIEW_INDIVIDUAL',
        sub: [],
    },
];

const superAdminMenu = globalSidebar.superAdminMenus;
const groupAdminMenu = globalSidebar.groupAdminMenus;
const clusterAdminMenu = globalSidebar.clusterAdminMenus;
const hospitalAdminMenu = globalSidebar.hospitalAdminMenus;
const providerMenu = globalSidebar.providerMenus;

@connect(state => ({
    uiPermission: usersGetters.getUiPermission({ root: true })(state),
    role: usersGetters.getLoggedInRole({ root: true })(state),
    organisation: usersGetters.getLoggedInOrganization({ root: true })(state),
    groupOrganisation: usersGetters.getLoggedInGroupOrganization({ root: true })(state),
}))
@withResource([{ resourceType: resourceTypes.USERS }])
class PrivateRoute extends PureComponent {
    render() {
        const {
            data,
            component: Component,
            authorizations = [],
            uiPermission = [],
            role,
            organisation: { type = '' } = {},
            history,
            location: { state: { mode = '' } = {} } = {},
            groupOrganisation: { isCovidOrg, isKioskOrg },
            ...rest
        } = this.props;

        const loggedInRole = gettersOf(resourceTypes.USERS).getLoggedInRole()(data);
        const loggedInOrgType = R.prop('type', gettersOf(resourceTypes.USERS).getLoggedInOrganization()(data));

        // Validate UI Permission
        const locationPathname = window.location.pathname;
        let curPath = locationPathname;
        const includeWsb = curPath.includes('wsb');
        if (includeWsb) {
            curPath = curPath.substr(9, curPath.length);
        } else {
            curPath = curPath.substr(5, curPath.length);
            curPath = curPath.substr(0, curPath.indexOf('/'));
        }
        const getSidebarAccess = uiPermission.filter(i => i.category === 'SIDEBAR' && i.access === 'ALLOW');

        let curMenu = [];

        const isCovidMenu = isCovidOrg ?
            providerMenu.concat({
                id: uuidv4(),
                icon: < PersonOutline / > ,
                label: 'Discharge',
                path: 'discharge-covid',
                resource: 'DISCHARGE',
            }) :
            providerMenu;

        switch (true) {
            case role === ROLES.SUPERADMIN:
                curMenu = superAdminMenu;
                break;
            case role === ROLES.ADMIN && type === ORG_TYPES.GROUP:
                curMenu = groupAdminMenu;
                break;
            case role === ROLES.ADMIN && type === ORG_TYPES.CLUSTER:
                curMenu = clusterAdminMenu;
                break;
            case role === ROLES.ADMIN && type === ORG_TYPES.HOSPITAL_CLINIC:
                curMenu = hospitalAdminMenu;
                break;
            case [
                ROLES.DOCTOR,
                ROLES.NURSE,
                ROLES.CARE_MANAGER,
                ROLES.PHYSIOTHERAPIST,
                ROLES.DIETICIAN,
                ROLES.OTHERS,
            ].includes(role):
                curMenu = isCovidMenu;
                break;
            default:
                curMenu = [];
        }

        if (role !== ROLES.SUPERADMIN)
            curMenu = curMenu.filter(
                i =>
                getSidebarAccess.map(a => a.resource).includes(i.resource) ||
                i.path === 'discharge-covid' ||
                (isKioskOrg && i.path === 'kiosk') ||
                (i.path === 'adherence-report'),
            );
        const aclSettings =
            role === ROLES.SUPERADMIN ||
            R.pathOr('DENY', ['access'], uiPermission.find(i => i.resource === 'PERMISSION_MENU')) === 'ALLOW';
        const findMenuByPath = curMenu.find(f => curPath.includes(f.path));
        const validateAclRoute = curPath.includes('acl') ? aclSettings : !!findMenuByPath;
        let validateRoute = authorizations.length === 0 || validateAclRoute;
        const authorizeRoute = authorize({
            role: loggedInRole,
            orgType: loggedInOrgType,
        })(authorizations);
        const allMenus = [
            ...superAdminMenu,
            ...groupAdminMenu,
            ...clusterAdminMenu,
            ...hospitalAdminMenu,
            ...isCovidMenu, [{ label: 'ACL', path: 'acl' }],
        ];
        const errLabel = R.pathOr('', ['label'], allMenus.find(f => curPath.includes(f.path)));

        // handle global route didn't have permission
        if (curPath === 'global') validateRoute = true;
        // handle validate child route
        if (!includeWsb && mode !== 'edit' && role !== ROLES.SUPERADMIN && validateRoute) {
            const isCreate = window.location.pathname.includes('create');
            let operationDetail = ['CREATE'];
            if (!isCreate) {
                operationDetail = ['VIEW_INDIVIDUAL', 'ISSUE_CONSUMABLE'];
                if (curPath === 'programs') {
                    operationDetail.push('VIEW_INDIVIDUAL_PROGRAMME_PROVIDERS_ACL');
                } else if (curPath === 'consumables') {
                    operationDetail.push('ISSUE_CONSUMABLE_PROVIDERS_ACL');
                } else if (curPath === 'inventory') {
                    operationDetail.push('VIEW_INDIVIDUAL_INVENTORY_PROVIDERS_ACL');
                }
            }
            const curRoutes = routes.find(f => f.path === curPath && operationDetail.includes(f.operation));
            if (curRoutes) {
                if (curRoutes.operation === 'SUB') {
                    // Validate sub path
                    const splitPathname = locationPathname.split('/');
                    const getIndexRoute = splitPathname.indexOf(curPath);
                    const subPath = splitPathname[getIndexRoute + 2];
                    const getCurrentSubRoutes = curRoutes.sub.find(f => f.path === subPath);
                    if (getCurrentSubRoutes) {
                        validateRoute =
                            R.pathOr(
                                'DENY', ['access'],
                                uiPermission.find(
                                    i => i.resource === getCurrentSubRoutes.resource && i.operation === getCurrentSubRoutes.operation,
                                ),
                            ) === 'ALLOW';
                    }
                } else {
                    validateRoute =
                        R.pathOr(
                            'DENY', ['access'],
                            uiPermission.find(i => i.resource === curRoutes.resource && operationDetail.includes(i.operation)),
                        ) === 'ALLOW';
                }
            }
        }

        // default route go back
        const route = R.pathOr('', [0, 'path'], curMenu);
        let path = `/app/wsb/${route}`;
        if (route === '') {
            const currentRole = (role) ? role : '';
            path = `/app/wsb/${currentRole.includes('ADMIN') ? 'orgs' : 'programs'}`;
        }

        // handling reporting menu
        if (curPath == 'reporting') validateRoute = true;

        return ( <
            Route {...rest }
            render = {
                // eslint-disable-next-line
                routerProps =>
                loggedInRole ? (
                    authorizeRoute && validateRoute ? ( <
                        Component {...routerProps } {...rest }
                        />
                    ) : ( <
                        Unauthorized authorizations = { authorizations }
                        errLabel = { errLabel }
                        includeWsb = { includeWsb }
                        history = { history }
                        path = { path }
                        props = { this.props }
                        />
                    )
                ) : ( <
                    Redirect to = {
                        { pathname: '/auth', state: { referrer: ((curPath.includes('unauthorized')) ? '' : routerProps.location) } } }
                    />
                )
            }
            />
        );
    }
}

export default PrivateRoute;