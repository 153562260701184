const styleFullScreenOverlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,0.2)',
  width: '100%',
  height: '100%',
  zIndex: 999,
};
const styleCenterOnScreen = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
};
const styleTextOnScreen = {
  fontSize: '19px',
  fontWeight: 'bold',
  position: 'fixed',
  color: 'white',
  width: '100%',
  textAlign: 'center',
  top: '56%',
  zIndex: 9999,
};
export default () => (
    <div>
      <div style={styleFullScreenOverlay} />
      <span style={styleTextOnScreen}>
        We are currently processing your registration and payment. It might take a while. <br />
        Please do not click back or stop the transaction to avoid duplications.
      </span>
      <div style={styleCenterOnScreen}>
        <div className="sk-rotating-plane" />
      </div>
    </div>
);
