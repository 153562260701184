import * as R from 'ramda';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';

const getDisplayName = ComposedComponent => ComposedComponent.displayName || ComposedComponent.name || 'Component';

const getIdsObject = R.pipe(
  R.map(({ name, value }) => ({ [name]: value })),
  R.mergeAll,
);

const joinSpecificValue = R.curry((getProp, fromProp, value, arr) =>
  R.join(', ', R.map(R.trim, R.map(R.prop(getProp))(R.filter(R.compose(R.flip(R.contains)(value), R.prop(fromProp)), arr)))));

const getQueriesObject = R.pipe(
  R.map(({ name, value }) => ({ [name]: value })),
  R.mergeAll,
);

const getQueriesString = R.pipe(
  R.filter(({ value }) => value),
  R.map(({ name, value }) => `${name}=${value}`),
  R.join('&'),
  R.unless(R.isEmpty(), R.concat('?')),
);

const getQueriesStringWithArray = (queries = []) => {
  if (queries.length === 0) return '';
  const queryParams = queries.map(query => {
    const { name, value } = query;
    if (Array.isArray(value)) {
      return value.map(v => {
        return `${name}=${v}`;
      }).join('&');
    }
    return `${name}=${value}`;
  }).join('&');
  return `?${queryParams}`;
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes <= 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Byte(s)', 'KB(s)', 'MB(s)', 'GB(s)', 'TB(s)', 'PB(s)', 'EB(s)', 'ZB(s)', 'YB(s)'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(decimals))} ${sizes[i]}`;
};

const round = (number, precision) => {
  const shift = (num, exponent) => {
    const numArray = ('' + num).split('e');
    return +(numArray[0] + 'e' + (numArray[1] ? (+numArray[1] + exponent) : exponent));
  };
  return shift(Math.round(shift(number, +precision)), -precision);
};

const memoize = (method, { path = [], timeout = Infinity } = {}) => {
  const cache = {};
  const fresh = {};

  /* eslint-disable */
  return function () {
    // NOTE: arguments is an Array-like object
    let renew = false;
    const args = R.when(
      // ASSUME: Last element of arguments is a potential option object for memoize
      argsList => R.length(argsList) > 0 && R.both(R.is(Object), R.has('memoize'))(R.last(argsList)),
      argsList => ({ memoize: { renew } } = R.last(argsList)) && R.init(argsList),
    )(Array.from(arguments));

    const cachedArgs = JSON.stringify(R.path(path, args));
    const invoke = renew || !cache[cachedArgs] || !fresh[cachedArgs];

    // NOTE: We can cache Promise as well (including rejected Promise)!!!
    cache[cachedArgs] = invoke ? method.apply(this, args) : cache[cachedArgs];

    invoke && do {
      fresh[cachedArgs] = true;
      timeout !== Infinity && setTimeout(() => fresh[cachedArgs] = false, timeout);
    }

    return cache[cachedArgs];
  };
  /* eslint-enable */
};

// NOTE: Order of comparison is important when their set of props are different
const shallowDiff = R.curry((a, b) => {
  let diffProps = '';
  R.forEachObjIndexed((v, k) => { v !== b[k] && (diffProps += `${k}, `); })(a);
  return R.dropLast(2, diffProps);
});

// TODO: Rename to findByProp
const findBy = R.curry((name, value, arr) => R.find(R.propEq(name, value), arr));
const findByPath = R.curry((path, value, arr) => R.find(R.pathEq(path, value), arr));
const findIndexBy = R.curry((name, value, arr) => R.findIndex(R.propEq(name, value), arr));
const findIndexByPath = R.curry((path, value, arr) => R.findIndex(R.pathEq(path, value), arr));

const switchProp = R.curry((toProp, fromProp, fromValue, arr) =>
  R.pipe(R.find(R.propEq(fromProp, fromValue)), R.unless(R.isNil, R.prop(toProp)))(arr));
const switchPath = R.curry((toPath, fromPath, fromValue, arr) =>
  R.pipe(R.find(R.pathEq(fromPath, fromValue)), R.unless(R.isNil, R.path(toPath)))(arr));

const toObjBy = R.curry((name, arr) => R.pipe(
  R.groupBy(R.prop(name)),
  R.map(R.prop(0)),
  R.omit(['undefined']),
)(arr));

const removeBy = R.curry((name, value, arr) => R.reject(R.propEq(name, value))(arr));
const removeByPath = R.curry((path, value, arr) => R.reject(R.pathEq(path, value))(arr));

const initialsOf = (name, noLetters = 2, delimiter = ' ', binder = '') =>
  R.pipe(R.split(delimiter), R.map(R.head), R.join(binder), R.take(noLetters))(name);

const gothumb2DataUrl = async gothumbFilename =>
  gothumbFilename && fetchGoThumbSignedUrl(gothumbFilename)
    .then(url => new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const dataUrl = canvas.toDataURL('image/jpeg', 1);
        resolve(dataUrl);
      };
      img.crossOrigin = 'anonymous';
      img.src = url;
      // make sure the load event fires for cached images too
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = url;
      }
    }))
    .catch((error) => {
      console.warn(
        `%cUnable to fetch image from gothumb for ${gothumbFilename}`,
        'font-size: 12px; color: lightcoral',
        error,
      );
      return null;
    });

const determinePhotoStatus = (photo) => {
  const dataUrlRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  let photoStatus = null;
  if (photo === '') {
    photoStatus = 'EMPTY';
  } else if (photo.match(dataUrlRegex)) {
    photoStatus = 'UPLOAD';
  } else {
    photoStatus = 'RETAIN';
  }

  return {
    photoStatus,
    file_suffix: '.jpeg',
    data: R.replace('data:image/jpeg;base64,', '', photo),
  };
};

const updateEnvConfig = (API_URL, GOTHUMB_URL) => {
  window.MILO_CONFIG.API_URL = API_URL;
  window.MILO_CONFIG.GOTHUMB_UPLOAD_URL = `${GOTHUMB_URL}/upload`;
  window.MILO_CONFIG.GOTHUMB_UPLOAD_URL_BASE64 = `${GOTHUMB_URL}/uploadBase64`;
  window.MILO_CONFIG.GOTHUMB_DOWNLOAD_SIGNED_URL = `${GOTHUMB_URL}/file`;
};

export {
  getDisplayName,
  getIdsObject,
  getQueriesObject,
  getQueriesString,
  formatBytes,
  round,
  memoize,
  shallowDiff,
  findBy,
  findByPath,
  findIndexBy,
  findIndexByPath,
  switchProp,
  switchPath,
  toObjBy,
  removeBy,
  removeByPath,
  initialsOf,
  gothumb2DataUrl,
  updateEnvConfig,
  joinSpecificValue,
  getQueriesStringWithArray,
  determinePhotoStatus
};
