/* eslint-disable */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import IconAccessDenied from '../assets/IconAccessDenied.png';

const styles = theme => ({
  card: {
    maxWidth: 345,
    textAlign: 'center',
    margin: `${theme.spacing(5)}px auto`,
  },
  media: {
    padding: `${theme.spacing(3)}px`,
    height: 200,
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  icon: {
    textAlign: 'center',
    paddingTop: '150px',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
  },
  desc: {
    textAlign: 'center',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    paddingTop: '5px',
    font: 'normal normal normal 16px/20px Roboto',
    letterSpacing: '0px'
  },
  content: {
    textAlign: 'center',
    width: '87vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    paddingTop: '5px',
    font: 'normal normal bold 34px/40px Roboto',
    letterSpacing: '0px',
  },
  button: {
    width: '180px',
    color: 'white',
    backgroundColor: '#ff5100',
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
});

const includeWsb = window.location.href.includes('/wsb/');

const Unauthorized = ({ classes, errLabel }) => (
  <Grid>
    <Grid style={{ width: '100%' }}>
      <Typography
        className={classes.icon}
        style={{ width: includeWsb ? '87vw' : '100%' }}
      >
        <img
          src={IconAccessDenied}
          style={{ width: '250px' }}
        />
      </Typography>
      <Typography variant="h5" component="h2" className={classes.content} style={{ width: includeWsb ? '87vw' : '100%' }}>Access Denied {errLabel}</Typography>
      <Typography variant='caption' className={classes.desc} style={{ width: includeWsb ? '87vw' : '100%', display: 'block' }}>
        All information in the system has restricted access. <br /> You are seeing this because you do not have the appropriate access rights. <br /> For access rights or clarifications, please contact your Administrator.
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(styles)(Unauthorized);