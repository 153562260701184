/* eslint-disable */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { CardMedia, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  card: {
    maxWidth: 345,
    textAlign: 'center',
    margin: `${theme.spacing(5)}px auto`,
  },
  media: {
    padding: `${theme.spacing(3)}px`,
    height: 200,
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

const WIP = ({ classes }) => (
  <Card elevation={4} className={classes.card}>
    <CardMedia image='/assets/img/wip.gif' title=' ' component='img' className={classes.media} />
    <CardContent>
      <Typography variant="h5" component="h2">Your page is coming soon</Typography>
      <Typography component="p">Thank you for your patience</Typography>
    </CardContent>
  </Card>
);

export default withStyles(styles)(WIP);