import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import * as R from 'ramda';
import uuidv4 from 'uuid/v4';
import { ImageAvatar } from 'utilsModule/components';

class ImageAvatarGroup extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      gothumb: PropTypes.bool,
      src: PropTypes.string,
      defaultUrl: PropTypes.string,
      alt: PropTypes.node,
      tooltip: PropTypes.string,
    })).isRequired,
    containerProps: PropTypes.shape({
      spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
      justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around']),
      alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
    }),
  };

  static defaultProps = {
    items: [],
    containerProps: {},
  };

  render() {
    const { items, containerProps } = this.props;

    return (
      <Grid container spacing={1} {...containerProps}>
        {R.map(props => (
          <Grid item key={uuidv4()}>
            <ImageAvatar {...props} />
          </Grid>
        ))(items)}
      </Grid>
    );
  }
}

export default ImageAvatarGroup;
