/* eslint-disable react/react-in-jsx-scope */
import uuidv4 from 'uuid/v4';
import {
    Update,
    PersonOutline,
    FavoriteBorder,
    Business,
    NotificationsNone,
    Receipt,
    ShoppingBasket,
    SpeakerNoteIcon,
    PieChartIcon
} from 'utilsModule/material-ui-icons';

const superAdminMenus = [{
    id: uuidv4(),
    icon: < Business />,
    label: 'Organisations',
    path: 'orgs',
},
{
    id: uuidv4(),
    icon: < Business />,
    label: 'Charge Code',
    path: 'chargecode',
},
{
    id: uuidv4(),
    icon: < Business />,
    label: 'Inventory',
    path: 'inventory',
},
{
    id: uuidv4(),
    icon: < Receipt />,
    label: 'Invoice',
    path: 'orgsInvoices',
},
{
    id: uuidv4(),
    icon: < SpeakerNoteIcon />,
    label: 'Surveys',
    path: 'feedback',
},
];

const groupAdminMenus = [{
    id: uuidv4(),
    icon: < Business />,
    label: 'Organisations',
    path: 'orgs',
    resource: 'ORGANISATION',
},];

const clusterAdminMenus = [{
    id: uuidv4(),
    icon: < Business />,
    label: 'Organisations',
    path: 'orgs',
    resource: 'ORGANISATION',
},];

const hospitalAdminMenus = [
    // { id: uuidv4(), icon: <Business />, label: 'Organisations', path: 'orgs' },
    {
        id: uuidv4(),
        icon: < Update />,
        label: 'Providers',
        path: 'providers',
        resource: 'PROVIDER',
    },
    {
        id: uuidv4(),
        icon: < PersonOutline />,
        label: 'Care Teams',
        path: 'careteams',
        resource: 'CARE_TEAM',
    },
    {
        id: uuidv4(),
        icon: < FavoriteBorder />,
        label: 'Programmes',
        path: 'programs',
        resource: 'ADMIN_PROGRAMME',
    },
    {
        id: uuidv4(),
        icon: < Business />,
        label: 'Charge Code',
        path: 'chargecode',
        resource: 'CHARGE_CODE',
    },
    {
        id: uuidv4(),
        icon: < ShoppingBasket />,
        label: 'Consumables',
        path: 'consumables',
        resource: 'CONSUMABLE',
    },
    {
        id: uuidv4(),
        icon: < Business />,
        label: 'Inventory',
        path: 'inventory',
        resource: 'INVENTORY',
    },
    {
        id: uuidv4(),
        icon: < Receipt />,
        label: 'Invoices',
        path: 'invoices',
        resource: 'INVOICE',
    },
    {
        id: uuidv4(),
        icon: < FavoriteBorder />,
        label: 'Kiosk',
        path: 'kiosk',
        resource: 'KIOSK',
    },
];

const providerMenus = [{
    id: uuidv4(),
    icon: < NotificationsNone />,
    label: 'Alerts',
    path: 'alerts',
    resource: 'ALERT',
},
{
    id: uuidv4(),
    icon: < PersonOutline />,
    label: 'Participants',
    path: 'participants',
    resource: 'PARTICIPANT',
},
{
    id: uuidv4(),
    icon: < FavoriteBorder />,
    label: 'Programmes',
    path: 'programs',
    resource: 'ADMIN_PROGRAMME',
},
{
    id: uuidv4(),
    icon: < ShoppingBasket />,
    label: 'Consumables',
    path: 'consumables',
    resource: 'CONSUMABLE',
},
{
    id: uuidv4(),
    icon: < Business />,
    label: 'Inventory',
    path: 'inventory',
    resource: 'INVENTORY',
},
{
    id: uuidv4(),
    icon: < FavoriteBorder />,
    label: 'Kiosk',
    path: 'kiosk',
    resource: 'KIOSK',
  },
  {
    id: uuidv4(),
    icon: <Business />,
    label: 'Adherence Report',
    path: 'adherence-report',
    resource: 'ADHERENCE_REPORT',
  },
    {
        id: uuidv4(),
        icon: <PieChartIcon />,
        label: 'Reports',
        path: 'reporting',
        resource: 'PARTICIPANT',
    }
];

const participantTabList = [{
    id: 'programme',
    value: 'program',
    operation: 'VIEW_PROGRAMME',
    path: 'program',
},
{
    id: 'vitals',
    value: 'vitals',
    operation: 'VIEW_VITAL',
    path: 'vitals',
},
{
    id: 'care tasks',
    value: 'caretask',
    operation: 'VIEW_CARE_TASK',
    path: 'caretask',
},
{
    id: 'adherence',
    value: 'adherence',
    operation: 'VIEW_PROGRAMME',
    path: 'profile',
},
{
    id: 'devices',
    value: 'devices',
    operation: 'VIEW_DEVICE',
    path: 'devices',
},
{
    id: 'alerts',
    value: 'alerts',
    operation: 'VIEW_ALERT',
    path: 'alerts',
},
{
    id: 'profile',
    value: 'profile',
    operation: 'VIEW_PROFILE',
    path: 'profile',
},
{
    id: 'reporting',
    value: 'reporting',
    operation: 'VIEW_PROGRAMME',
    path: 'reporting'
},
];

export default {
    superAdminMenus,
    groupAdminMenus,
    clusterAdminMenus,
    hospitalAdminMenus,
    providerMenus,
    participantTabList,
};
