import React from 'react';
import { Loading } from 'utilsModule/components';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { getQueriesString } from 'utilsModule';
import * as R from 'ramda';

// const API_URL = 'http://api.ipstack.com';
const API_URL = 'https://api.ipgeolocation.io';
const API_KEY = 'e0bb483ba7474d51b443c00ababcc6cb';

export const withIPLocation = (options = [{ name: 'fields', value: 'country_name,country_code2' }]) => (Component) => {
  @withResource([
    {
      resourceType: resourceTypes.CONFIGS,
      method: 'retrieveLoginCountryCodes',
      input: {
        params: {
          queries: [
            { name: 'global', value: window.MILO_CONFIG.ENABLE_GLOBAL_TABLE.toString() },
          ],
        },
      },
      options: { runOnDidMount: true },
    },
    { resourceType: resourceTypes.USERS },
  ])
  class WithIPLocation extends React.PureComponent {
    componentDidMount() {
      const { ENABLE_IP_GEOLOCATION } = window.MILO_CONFIG;

      if (ENABLE_IP_GEOLOCATION) {
        fetch(`${API_URL}/ipgeo${R.pipe(R.prepend({ name: 'apiKey', value: API_KEY }), getQueriesString)(options)}`, { method: 'GET' })
          .then(res => res.json())
          .then((ipLocation) => {
            this.ipLocationReady = true;
            this.setState({ ipLocation });
          })
          .catch((error) => {
            console.warn(`%cUnable to fetch location based on browser's ip. Fallback to Singapore`, 'font-size: 12px; color: lightcoral', error);
            this.ipLocationReady = true;
            this.setState({
              ipLocation: {
                country_name: 'Singapore',
                country_code: 'SG',
              },
            });
          });
      } else {
        this.ipLocationReady = true;
        this.setState({
          ipLocation: {
            country_name: 'Singapore',
            country_code: 'SG',
          },
        });
      }
    }

    getCountryCodeByIp = () => {
      const { data } = this.props;
      const { ipLocation: { country_name: countryName } } = this.state;
      const countries = gettersOf(resourceTypes.CONFIGS).getLoginCountryCodes()(data);

      // Only return countryCode for supporting countries, otherwise return 65 as default
      return countries.find(c => c.value === countryName) ? countries.find(c => c.value === countryName).countryCode : '65';
    }

    render() {
      if (!this.ipLocationReady) return <Loading />;

      const { ipLocation } = this.state;
      return <Component {...this.props} ipLocation={ipLocation} getCountryCodeByIp={this.getCountryCodeByIp} />;
    }
  }

  return WithIPLocation;
};

export default {};
