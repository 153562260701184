import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import MoreVert from '@material-ui/icons/MoreVert';
import { MenuItem, Menu } from '@material-ui/core';
import * as R from 'ramda';

const styles = (theme) => ({
  moreIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'grey',
    float: 'right',
  },
});

@withStyles(styles, { index: 1 })
class PopupMenu extends React.Component {
  static propTypes = {
    menuItems: PropTypes.array.isRequired,
  };

  state = {
    anchorEl: null,
  };

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  render() {
    const { anchorEl } = this.state;
    const { classes, showOptionFromRow = false, viewOnly = null, ...rest } = this.props;
    let { showOption = true, menuItems } = this.props;

    if (showOptionFromRow) showOption = this.props.row.showOption;

    if (viewOnly) {
      const rowField = R.pathOr('', viewOnly.rowPath, this.props);
      const match = viewOnly.match.includes(rowField);
      if (match) menuItems = [menuItems[0]];
    }

    return (
      showOption && (
        <div>
          <MoreVert
            aria-owns={anchorEl ? 'more-popup-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={classes.moreIcon}
          />
          <Menu id="more-popup-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={this.handleClose}>
            {menuItems.map(({ id, component: Component, inject, disabled, onClick, ...props }) => {
              const { disabled: injectedDisabled = null, onClick: injectedOnClick, ...injectedProps } = inject
                ? inject(rest)
                : {};

              return (
                <MenuItem
                  key={id}
                  disabled={injectedDisabled !== null ? injectedDisabled : !!disabled}
                  onClick={() => {
                    onClick && onClick();
                    injectedOnClick && injectedOnClick();
                    this.handleClose();
                  }}
                >
                  <Component {...props} {...injectedProps} />
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )
    );
  }
}

export default PopupMenu;
