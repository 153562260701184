import CircularProgress from '@material-ui/core/CircularProgress';

const styleFullScreenOverlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  background: 'rgba(0, 0, 0, 0.3)',
  width: '100%',
  height: '100%',
  zIndex: 99999,
};
const styleCenterOnScreen = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
export default ({ fullScreenOverlay = true }) => (
  <div>
    {fullScreenOverlay && <div style={styleFullScreenOverlay} />}
    <div style={fullScreenOverlay ? styleCenterOnScreen : null}>
      <CircularProgress style={{ color: '#FF5722' }}/>
    </div>
  </div>
);
