import React from 'react';
import { withRouter } from 'react-router';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ArrowBack } from 'utilsModule/material-ui-icons';
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import DialogCustomerSupportContactComponent from '../../app/main/layouts/DialogCustomerSupportContact';

const styles = {
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(0,0,0,0.06)',
      borderRadius: 10,
    },
  },
  arrowClick: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  },
  labelStyle: {
    display: 'inline-block',
    paddingRight: 20,
    letterSpacing: '0.5px',
  },
};

@withRouter
@withStyles(styles, { index: 1 })
class Back extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialogCustomerSupportContact: false,
    };
  }

  toggledialogCustomerSupportContact = async (open) => {
    this.setState({ openDialogCustomerSupportContact: open });
  }

  onClickToWebsite = () => { window.location.href = 'https://www.healthbeats.co'; }

  onClick = () => {
    const { path, history, state } = this.props;
    return path ? history.replace(path, state) : history.goBack();
  }

  render() {
    const {
      label, classes, className, backToWebsite,
    } = this.props;

    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classNames(className)}
        justify="space-between"
      >  { /** justify="space-between" because might want to show showCustomerSupportIcon **/}
        <Grid
          item
          className={classNames(classes.clickable)}
          onClick={backToWebsite ? this.onClickToWebsite : this.onClick}>
          <IconButton className={classes.arrowClick} >
            <ArrowBack />
          </IconButton>
          <Typography style={styles.labelStyle} variant='body1'>{label}</Typography>
        </Grid>


        <DialogCustomerSupportContactComponent
          open={this.state.openDialogCustomerSupportContact}
          toggledialogCustomerSupportContact={this.toggledialogCustomerSupportContact} />

        { this.props.showCustomerSupportIcon &&
        <Grid item onClick={() => this.toggledialogCustomerSupportContact(true)}>
          <IconButton title="Help" style={{ fontSize: '40px', color: '#9e9e9e' }}>
            <HelpIcon style={{ width: '40px', height: '40px' }} />
          </IconButton>
        </Grid>}
      </Grid>
    );
  }
}

export default Back;
