import { getSessionToken } from '../api/rest/utils';

const uploadToGoThumb = async (params) => {
  const { GOTHUMB_UPLOAD_URL } = window.MILO_CONFIG;
  return fetch(GOTHUMB_UPLOAD_URL, params).then((response) => response.text());
};

const fetchGoThumbSignedUrl = async (filename, sessionToken = null) => {
  if (!filename || filename === null || filename === '' || filename.toString() === '[object Object]') return null;

  const { API_URL } = window.MILO_CONFIG;

  const response = await fetch(`${API_URL}/gothumb/download/${filename}`, {
    method: 'GET',
    headers: {
      'session-token': sessionToken ? sessionToken : getSessionToken(),
    },
  });

  const data = await response.json();

  return data.value.toString();
};

export { uploadToGoThumb, fetchGoThumbSignedUrl };
